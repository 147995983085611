
const digest = 'f400d79be028d16418d6b5a89ead642ea0aa0a4b930c865aa9845ed36783b08a';
const css = `._negative_phxb9_1 {
  color: var(--color-red);
}

._hoursLabel_phxb9_5 {
  font-size: 0.5rem;
  margin-left: 2px;
  line-height: 1;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"negative":"_negative_phxb9_1","hoursLabel":"_hoursLabel_phxb9_5"};
export { css, digest };
  