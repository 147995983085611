
const digest = 'a6caa3ee515d47ae1f7df520f999c1cf3bf63ca6129117ce7bc97266db3bebe0';
const css = `._progress_14gif_1 {
  position: relative;
  --height: 5px;

  display: flex;
  height: 0.25rem;
  justify-content: flex-start;
  width: 100%;
  background: var(--color-legacy-gray-3);
}

._meter_14gif_12 {
  position: relative;
  display: flex;
  height: var(--height);
  max-width: 100%;
  width: var(--meter-width);
  background: var(--color-legacy-gray-3);
  border-radius: var(--height);
}

._meter_14gif_12,
._overageMeter_14gif_23,
._allocated_14gif_24 {
  position: relative;

  transform-origin: top center;
  transition: transform 0.2s ease-in-out;
}

._meterSection_14gif_31 {
  border-radius: var(--height);
  transition: transform 0.2s ease-in-out;
}

._meter_14gif_12:hover,
._meter_14gif_12:hover + ._overageMeter_14gif_23,
._allocated_14gif_24:hover {
  cursor: pointer;
  transform: scaleY(1.25);
}

._consumed_14gif_43 {
  background: var(--project-color);
}

._allocated_14gif_24 {
  height: var(--height);
  background-color: var(--background-color);
  background-image: repeating-linear-gradient(
    -45deg,
    var(--color-legacy-gray-3),
    var(--color-legacy-gray-3) 0.1rem,
    rgba(255, 255, 255, 0) 0.1rem,
    rgba(255, 255, 255, 0) 0.2rem
  );
}

._remaining_14gif_59 {
  background: var(--color-legacy-gray-3);
}

._overageMeter_14gif_23 {
  position: relative;

  display: flex;
  height: var(--height);
  padding-bottom: 2px;
  max-width: 100%;
  width: var(--overage-width);
}

._overage_14gif_23 {
  background: var(--color-red);
  border-radius: var(--height);
  height: var(--height);
  margin-bottom: 2px;
  transform-origin: top center;
}

._overage_14gif_23:hover {
  cursor: pointer;
  transform: scaleY(1.25);
}

._averaged_14gif_86 {
  position: absolute;
  top: 0;

  background: var(--color-warning);
  border-radius: var(--height);
  height: var(--height);
  transition: transform 0.1s ease-in-out;
  width: var(--height);
}

._averaged_14gif_86:hover {
  cursor: pointer;
  transform: scale(1.5);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"progress":"_progress_14gif_1","meter":"_meter_14gif_12","overageMeter":"_overageMeter_14gif_23","allocated":"_allocated_14gif_24","meterSection":"_meterSection_14gif_31","consumed":"_consumed_14gif_43","remaining":"_remaining_14gif_59","overage":"_overage_14gif_23","averaged":"_averaged_14gif_86"};
export { css, digest };
  