
const digest = '085fb766e026f46ff07fc648c69f61c42a048bcf4796d4cf1b21b335d3e6a5e1';
const css = `._emptyStateButtons_18lg8_1 {
  margin-top: 0.5rem;
  display: flex;
}

._emptyStateSample_18lg8_6 {
  margin-left: 1rem;
}

._retainersCard_18lg8_10 {
  text-align: center;
  background: #fff;
  padding: 1.5rem;
  border-radius: 0.25em;
}

._invoicingForRetainers_18lg8_17 {
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

._textMuted_18lg8_24 {
  color: var(--color-neutral-10);
}

._createInvoiceButton_18lg8_28 {
  border-radius: var(--border-radius);
  border: 0;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 0.7rem 1rem;
  transition: all 0.25s var(--transition);
  background: #5840e0;
  color: #fff;
  margin-top: 1rem;
}

._createInvoiceLink_18lg8_41 {
  color: #5840e0;
  font-weight: bold;
  cursor: pointer;
  display: inline;
  margin-left: 0.3rem;
}

._createInvoiceLink_18lg8_41:hover {
  text-decoration: underline;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"emptyStateButtons":"_emptyStateButtons_18lg8_1","emptyStateSample":"_emptyStateSample_18lg8_6","retainersCard":"_retainersCard_18lg8_10","invoicingForRetainers":"_invoicingForRetainers_18lg8_17","textMuted":"_textMuted_18lg8_24","createInvoiceButton":"_createInvoiceButton_18lg8_28","createInvoiceLink":"_createInvoiceLink_18lg8_41"};
export { css, digest };
  