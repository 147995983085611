
const digest = '4ae548c69b7abb46deaf49f36125dbf71a92314b75404b2dcfa60360d710519d';
const css = `._status_g9pw0_1 {
  border-radius: 50%;
  height: 0.375rem;
  margin: 0.25rem;
  width: 0.375rem;
}

/* Sizes */
._medium_g9pw0_9 {
  height: 0.375rem;
  width: 0.375rem;
}

._small_g9pw0_14 {
  height: 0.25rem;
  width: 0.25rem;
}

/* Colors */
._yellow_g9pw0_20 {
  background-color: var(--color-warning);
}

._green_g9pw0_24 {
  background-color: var(--color-green);
}

._red_g9pw0_28 {
  background-color: var(--color-red);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"status":"_status_g9pw0_1","medium":"_medium_g9pw0_9","small":"_small_g9pw0_14","yellow":"_yellow_g9pw0_20","green":"_green_g9pw0_24","red":"_red_g9pw0_28"};
export { css, digest };
  